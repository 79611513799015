import { RiFacebookFill } from "react-icons/ri";
import { AiOutlineInstagram } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";

export const GENERAL_INFO = {
  openHours: [
    { days: "Monday", from: "9am", to: "6pm" },
    { days: "Tuesday", from: "9am", to: "6pm" },
    { days: "Wednesday", from: "9am", to: "7pm" },
    { days: "Thursday", from: "9am", to: "7pm" },
    { days: "Friday", from: "9am", to: "7pm" },
    { days: "Saturday", from: "9am", to: "6pm" },
    { days: "Sunday", from: "10am", to: "5pm" },
    // { days: "Weekends & Publish Holidays", from: "", to: "" },
  ],
  location: "Shop 8, 67 Cavendish Drive",
  address: "Shop 8, 67 Cavendish Drive, Manukau City Centre, Auckland 2104",
  phone: "(09) 2623456",
  email: "hi@tbbsalon.co.nz",
  // parking:
  //   "Parking is available on-site. If your appointment exceeds 90 minutes, kindly validate your parking ticket with us at the salon before your appointment begins. In case of fines, feel free to contact us for prompt resolution.",
};

export const MAP =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3186.866856482156!2d174.86916307488482!3d-36.98911118735817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4dc3f010dad3%3A0xdce2b690eeae72dc!2sShop%208%2F67%20Cavendish%20Drive%2C%20Manukau%20City%20Centre%2C%20Auckland%202104%2C%20New%20Zealand!5e0!3m2!1sen!2sus!4v1719849101785!5m2!1sen!2sus";

export const SOCIAL_NETWORK = [
  {
    title: "Follow on facebook",
    src: "https://www.facebook.com/TBB.TheBeautyBoutiqueNZ",
  },
  {
    title: "Follow on Instagram",
    src: "https://www.instagram.com/tbbsalon.nz/",
  },
  {
    title: "Send us an email",
    src: "hi@tbbsalon.co.nz",
  },
];

export const SOCIAL_NETWORK_ICON_MAP = {
  "Follow on facebook": <RiFacebookFill size={20} />,
  "Follow on Instagram": <AiOutlineInstagram size={20} />,
  "Send us an email": <HiOutlineMail size={20} />,
};

{
  /* <iframe
  src=""
  width="600"
  height="450"
  style="border:0;"
  allowfullscreen=""
  loading="lazy"
  referrerpolicy="no-referrer-when-downgrade"
></iframe>; */
}

const SERVICE_GROUP = [
  { id: "1", name: "1. Acrylic Nails 1" },
  { id: "2", name: "2. Acrylic Nails 2" },
  { id: "3", name: "3. Acrylic Nails 3" },
  { id: "4", name: "4. Acrylic Nails 4" },
];

const SERVICE_ITEM = [
  {
    id: "item-1",
    name: "Eyelash",
    group: "1",
    price: 70,
    regularPrice: 70,
    description: "",
  },
  {
    id: "item-2",
    name: "Eyelash",
    group: "2",
    price: 70,
    regularPrice: 70,
    description: "",
  },
  {
    id: "item-3",
    name: "Eyelash",
    group: "3",
    price: 70,
    regularPrice: 70,
    description: "",
  },
];
